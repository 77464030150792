/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUserInfo = /* GraphQL */ `
  mutation CreateUserInfo(
    $input: CreateUserInfoInput!
    $condition: ModelUserInfoConditionInput
  ) {
    createUserInfo(input: $input, condition: $condition) {
      id
      name
      surname
      Lat
      Lng
      Address
      balance
      email
      phoneNumber
      createdAt
      updatedAt
      driver
      discountPercentage
      discountAmount
      discountQty
      store
    }
  }
`;
export const updateUserInfo = /* GraphQL */ `
  mutation UpdateUserInfo(
    $input: UpdateUserInfoInput!
    $condition: ModelUserInfoConditionInput
  ) {
    updateUserInfo(input: $input, condition: $condition) {
      id
      name
      surname
      Lat
      Lng
      Address
      balance
      email
      phoneNumber
      createdAt
      updatedAt
      driver
      discountPercentage
      discountAmount
      discountQty
      store
    }
  }
`;
export const deleteUserInfo = /* GraphQL */ `
  mutation DeleteUserInfo(
    $input: DeleteUserInfoInput!
    $condition: ModelUserInfoConditionInput
  ) {
    deleteUserInfo(input: $input, condition: $condition) {
      id
      name
      surname
      Lat
      Lng
      Address
      balance
      email
      phoneNumber
      createdAt
      updatedAt
      driver
      discountPercentage
      discountAmount
      discountQty
      store
    }
  }
`;
export const createShop = /* GraphQL */ `
  mutation CreateShop(
    $input: CreateShopInput!
    $condition: ModelShopConditionInput
  ) {
    createShop(input: $input, condition: $condition) {
      id
      name
      Lat
      Lng
      Address
      balance
      email
      phoneNumber
      createdAt
      updatedAt
      logo
      busName
      website
      busReg
      social
      category
      ownerName
      ownerSurname
      ownerEmail
      carryStock
      physStore
    }
  }
`;
export const updateShop = /* GraphQL */ `
  mutation UpdateShop(
    $input: UpdateShopInput!
    $condition: ModelShopConditionInput
  ) {
    updateShop(input: $input, condition: $condition) {
      id
      name
      Lat
      Lng
      Address
      balance
      email
      phoneNumber
      createdAt
      updatedAt
      logo
      busName
      website
      busReg
      social
      category
      ownerName
      ownerSurname
      ownerEmail
      carryStock
      physStore
    }
  }
`;
export const deleteShop = /* GraphQL */ `
  mutation DeleteShop(
    $input: DeleteShopInput!
    $condition: ModelShopConditionInput
  ) {
    deleteShop(input: $input, condition: $condition) {
      id
      name
      Lat
      Lng
      Address
      balance
      email
      phoneNumber
      createdAt
      updatedAt
      logo
      busName
      website
      busReg
      social
      category
      ownerName
      ownerSurname
      ownerEmail
      carryStock
      physStore
    }
  }
`;
export const createShopRequest = /* GraphQL */ `
  mutation CreateShopRequest(
    $input: CreateShopRequestInput!
    $condition: ModelShopRequestConditionInput
  ) {
    createShopRequest(input: $input, condition: $condition) {
      id
      busName
      website
      busReg
      social
      category
      ownerName
      ownerSurname
      ownerEmail
      comments
      status
      carryStock
      physStore
      supplyRet
      unique
      brands
      createdAt
      updatedAt
    }
  }
`;
export const updateShopRequest = /* GraphQL */ `
  mutation UpdateShopRequest(
    $input: UpdateShopRequestInput!
    $condition: ModelShopRequestConditionInput
  ) {
    updateShopRequest(input: $input, condition: $condition) {
      id
      busName
      website
      busReg
      social
      category
      ownerName
      ownerSurname
      ownerEmail
      comments
      status
      carryStock
      physStore
      supplyRet
      unique
      brands
      createdAt
      updatedAt
    }
  }
`;
export const deleteShopRequest = /* GraphQL */ `
  mutation DeleteShopRequest(
    $input: DeleteShopRequestInput!
    $condition: ModelShopRequestConditionInput
  ) {
    deleteShopRequest(input: $input, condition: $condition) {
      id
      busName
      website
      busReg
      social
      category
      ownerName
      ownerSurname
      ownerEmail
      comments
      status
      carryStock
      physStore
      supplyRet
      unique
      brands
      createdAt
      updatedAt
    }
  }
`;
export const createProducts = /* GraphQL */ `
  mutation CreateProducts(
    $input: CreateProductsInput!
    $condition: ModelProductsConditionInput
  ) {
    createProducts(input: $input, condition: $condition) {
      id
      title
      brand
      price
      newPrice
      image
      images
      quantity
      description
      active
      request
      requestStatus
      category
      isle
      group
      available
      shop
      shopID
      shopLogo
      avgRating
      rating
      noRating
      DaySpecial
      discount
      sizes
      options
      colours
      promoCat
      promoted
      filterCat
      filterCatB
      gender
      ageGroup
      createdAt
      updatedAt
    }
  }
`;
export const updateProducts = /* GraphQL */ `
  mutation UpdateProducts(
    $input: UpdateProductsInput!
    $condition: ModelProductsConditionInput
  ) {
    updateProducts(input: $input, condition: $condition) {
      id
      title
      brand
      price
      newPrice
      image
      images
      quantity
      description
      active
      request
      requestStatus
      category
      isle
      group
      available
      shop
      shopID
      shopLogo
      avgRating
      rating
      noRating
      DaySpecial
      discount
      sizes
      options
      colours
      promoCat
      promoted
      filterCat
      filterCatB
      gender
      ageGroup
      createdAt
      updatedAt
    }
  }
`;
export const deleteProducts = /* GraphQL */ `
  mutation DeleteProducts(
    $input: DeleteProductsInput!
    $condition: ModelProductsConditionInput
  ) {
    deleteProducts(input: $input, condition: $condition) {
      id
      title
      brand
      price
      newPrice
      image
      images
      quantity
      description
      active
      request
      requestStatus
      category
      isle
      group
      available
      shop
      shopID
      shopLogo
      avgRating
      rating
      noRating
      DaySpecial
      discount
      sizes
      options
      colours
      promoCat
      promoted
      filterCat
      filterCatB
      gender
      ageGroup
      createdAt
      updatedAt
    }
  }
`;
export const createProductSizes = /* GraphQL */ `
  mutation CreateProductSizes(
    $input: CreateProductSizesInput!
    $condition: ModelProductSizesConditionInput
  ) {
    createProductSizes(input: $input, condition: $condition) {
      id
      name
      ProductID
      createdAt
      updatedAt
    }
  }
`;
export const updateProductSizes = /* GraphQL */ `
  mutation UpdateProductSizes(
    $input: UpdateProductSizesInput!
    $condition: ModelProductSizesConditionInput
  ) {
    updateProductSizes(input: $input, condition: $condition) {
      id
      name
      ProductID
      createdAt
      updatedAt
    }
  }
`;
export const deleteProductSizes = /* GraphQL */ `
  mutation DeleteProductSizes(
    $input: DeleteProductSizesInput!
    $condition: ModelProductSizesConditionInput
  ) {
    deleteProductSizes(input: $input, condition: $condition) {
      id
      name
      ProductID
      createdAt
      updatedAt
    }
  }
`;
export const createProductOptions = /* GraphQL */ `
  mutation CreateProductOptions(
    $input: CreateProductOptionsInput!
    $condition: ModelProductOptionsConditionInput
  ) {
    createProductOptions(input: $input, condition: $condition) {
      id
      name
      ProductID
      createdAt
      updatedAt
    }
  }
`;
export const updateProductOptions = /* GraphQL */ `
  mutation UpdateProductOptions(
    $input: UpdateProductOptionsInput!
    $condition: ModelProductOptionsConditionInput
  ) {
    updateProductOptions(input: $input, condition: $condition) {
      id
      name
      ProductID
      createdAt
      updatedAt
    }
  }
`;
export const deleteProductOptions = /* GraphQL */ `
  mutation DeleteProductOptions(
    $input: DeleteProductOptionsInput!
    $condition: ModelProductOptionsConditionInput
  ) {
    deleteProductOptions(input: $input, condition: $condition) {
      id
      name
      ProductID
      createdAt
      updatedAt
    }
  }
`;
export const createProductColours = /* GraphQL */ `
  mutation CreateProductColours(
    $input: CreateProductColoursInput!
    $condition: ModelProductColoursConditionInput
  ) {
    createProductColours(input: $input, condition: $condition) {
      id
      name
      ProductID
      createdAt
      updatedAt
    }
  }
`;
export const updateProductColours = /* GraphQL */ `
  mutation UpdateProductColours(
    $input: UpdateProductColoursInput!
    $condition: ModelProductColoursConditionInput
  ) {
    updateProductColours(input: $input, condition: $condition) {
      id
      name
      ProductID
      createdAt
      updatedAt
    }
  }
`;
export const deleteProductColours = /* GraphQL */ `
  mutation DeleteProductColours(
    $input: DeleteProductColoursInput!
    $condition: ModelProductColoursConditionInput
  ) {
    deleteProductColours(input: $input, condition: $condition) {
      id
      name
      ProductID
      createdAt
      updatedAt
    }
  }
`;
export const createProductOD = /* GraphQL */ `
  mutation CreateProductOD(
    $input: CreateProductODInput!
    $condition: ModelProductODConditionInput
  ) {
    createProductOD(input: $input, condition: $condition) {
      id
      title
      description
      image
      images
      options
      categoryA
      categoryB
      avgRating
      ratings
      DaySpecial
      discount
      price
      oldPrice
      createdAt
      updatedAt
    }
  }
`;
export const updateProductOD = /* GraphQL */ `
  mutation UpdateProductOD(
    $input: UpdateProductODInput!
    $condition: ModelProductODConditionInput
  ) {
    updateProductOD(input: $input, condition: $condition) {
      id
      title
      description
      image
      images
      options
      categoryA
      categoryB
      avgRating
      ratings
      DaySpecial
      discount
      price
      oldPrice
      createdAt
      updatedAt
    }
  }
`;
export const deleteProductOD = /* GraphQL */ `
  mutation DeleteProductOD(
    $input: DeleteProductODInput!
    $condition: ModelProductODConditionInput
  ) {
    deleteProductOD(input: $input, condition: $condition) {
      id
      title
      description
      image
      images
      options
      categoryA
      categoryB
      avgRating
      ratings
      DaySpecial
      discount
      price
      oldPrice
      createdAt
      updatedAt
    }
  }
`;
export const createCartProduct = /* GraphQL */ `
  mutation CreateCartProduct(
    $input: CreateCartProductInput!
    $condition: ModelCartProductConditionInput
  ) {
    createCartProduct(input: $input, condition: $condition) {
      id
      userSub
      quantity
      productID
      title
      price
      image
      brand
      cylinder
      category
      createdAt
      updatedAt
      colour
      size
      see
    }
  }
`;
export const updateCartProduct = /* GraphQL */ `
  mutation UpdateCartProduct(
    $input: UpdateCartProductInput!
    $condition: ModelCartProductConditionInput
  ) {
    updateCartProduct(input: $input, condition: $condition) {
      id
      userSub
      quantity
      productID
      title
      price
      image
      brand
      cylinder
      category
      createdAt
      updatedAt
      colour
      size
      see
    }
  }
`;
export const deleteCartProduct = /* GraphQL */ `
  mutation DeleteCartProduct(
    $input: DeleteCartProductInput!
    $condition: ModelCartProductConditionInput
  ) {
    deleteCartProduct(input: $input, condition: $condition) {
      id
      userSub
      quantity
      productID
      title
      price
      image
      brand
      cylinder
      category
      createdAt
      updatedAt
      colour
      size
      see
    }
  }
`;
export const createOrder = /* GraphQL */ `
  mutation CreateOrder(
    $input: CreateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    createOrder(input: $input, condition: $condition) {
      id
      userSub
      orderAmount
      paymentStatus
      deliveryStatus
      fullName
      phoneNumber
      address
      status
      statusB
      statusC
      lat
      lng
      country
      city
      amount
      qty
      driverID
      car
      model
      plate
      driverName
      driverSurname
      driverImage
      type
      shop
      shopID
      deliveryType
      deliveryAmount
      paymentMethod
      surname
      createdAt
      updatedAt
    }
  }
`;
export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder(
    $input: UpdateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    updateOrder(input: $input, condition: $condition) {
      id
      userSub
      orderAmount
      paymentStatus
      deliveryStatus
      fullName
      phoneNumber
      address
      status
      statusB
      statusC
      lat
      lng
      country
      city
      amount
      qty
      driverID
      car
      model
      plate
      driverName
      driverSurname
      driverImage
      type
      shop
      shopID
      deliveryType
      deliveryAmount
      paymentMethod
      surname
      createdAt
      updatedAt
    }
  }
`;
export const deleteOrder = /* GraphQL */ `
  mutation DeleteOrder(
    $input: DeleteOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    deleteOrder(input: $input, condition: $condition) {
      id
      userSub
      orderAmount
      paymentStatus
      deliveryStatus
      fullName
      phoneNumber
      address
      status
      statusB
      statusC
      lat
      lng
      country
      city
      amount
      qty
      driverID
      car
      model
      plate
      driverName
      driverSurname
      driverImage
      type
      shop
      shopID
      deliveryType
      deliveryAmount
      paymentMethod
      surname
      createdAt
      updatedAt
    }
  }
`;
export const createOrderProduct = /* GraphQL */ `
  mutation CreateOrderProduct(
    $input: CreateOrderProductInput!
    $condition: ModelOrderProductConditionInput
  ) {
    createOrderProduct(input: $input, condition: $condition) {
      id
      quantity
      price
      image
      title
      brand
      titilet
      cylinder
      productID
      orderID
      colour
      size
      createdAt
      updatedAt
    }
  }
`;
export const updateOrderProduct = /* GraphQL */ `
  mutation UpdateOrderProduct(
    $input: UpdateOrderProductInput!
    $condition: ModelOrderProductConditionInput
  ) {
    updateOrderProduct(input: $input, condition: $condition) {
      id
      quantity
      price
      image
      title
      brand
      titilet
      cylinder
      productID
      orderID
      colour
      size
      createdAt
      updatedAt
    }
  }
`;
export const deleteOrderProduct = /* GraphQL */ `
  mutation DeleteOrderProduct(
    $input: DeleteOrderProductInput!
    $condition: ModelOrderProductConditionInput
  ) {
    deleteOrderProduct(input: $input, condition: $condition) {
      id
      quantity
      price
      image
      title
      brand
      titilet
      cylinder
      productID
      orderID
      colour
      size
      createdAt
      updatedAt
    }
  }
`;
export const createFavourite = /* GraphQL */ `
  mutation CreateFavourite(
    $input: CreateFavouriteInput!
    $condition: ModelFavouriteConditionInput
  ) {
    createFavourite(input: $input, condition: $condition) {
      id
      productID
      title
      userSub
      price
      newPrice
      image
      images
      description
      category
      isle
      group
      available
      shop
      shopID
      shopLogo
      avgRating
      rating
      noRating
      DaySpecial
      discount
      sizes
      options
      colours
      createdAt
      updatedAt
    }
  }
`;
export const updateFavourite = /* GraphQL */ `
  mutation UpdateFavourite(
    $input: UpdateFavouriteInput!
    $condition: ModelFavouriteConditionInput
  ) {
    updateFavourite(input: $input, condition: $condition) {
      id
      productID
      title
      userSub
      price
      newPrice
      image
      images
      description
      category
      isle
      group
      available
      shop
      shopID
      shopLogo
      avgRating
      rating
      noRating
      DaySpecial
      discount
      sizes
      options
      colours
      createdAt
      updatedAt
    }
  }
`;
export const deleteFavourite = /* GraphQL */ `
  mutation DeleteFavourite(
    $input: DeleteFavouriteInput!
    $condition: ModelFavouriteConditionInput
  ) {
    deleteFavourite(input: $input, condition: $condition) {
      id
      productID
      title
      userSub
      price
      newPrice
      image
      images
      description
      category
      isle
      group
      available
      shop
      shopID
      shopLogo
      avgRating
      rating
      noRating
      DaySpecial
      discount
      sizes
      options
      colours
      createdAt
      updatedAt
    }
  }
`;
export const createDriverList = /* GraphQL */ `
  mutation CreateDriverList(
    $input: CreateDriverListInput!
    $condition: ModelDriverListConditionInput
  ) {
    createDriverList(input: $input, condition: $condition) {
      id
      name
      surname
      image
      active
      lat
      lng
      userID
      car
      model
      plate
      createdAt
      updatedAt
    }
  }
`;
export const updateDriverList = /* GraphQL */ `
  mutation UpdateDriverList(
    $input: UpdateDriverListInput!
    $condition: ModelDriverListConditionInput
  ) {
    updateDriverList(input: $input, condition: $condition) {
      id
      name
      surname
      image
      active
      lat
      lng
      userID
      car
      model
      plate
      createdAt
      updatedAt
    }
  }
`;
export const deleteDriverList = /* GraphQL */ `
  mutation DeleteDriverList(
    $input: DeleteDriverListInput!
    $condition: ModelDriverListConditionInput
  ) {
    deleteDriverList(input: $input, condition: $condition) {
      id
      name
      surname
      image
      active
      lat
      lng
      userID
      car
      model
      plate
      createdAt
      updatedAt
    }
  }
`;
export const createCategoryList = /* GraphQL */ `
  mutation CreateCategoryList(
    $input: CreateCategoryListInput!
    $condition: ModelCategoryListConditionInput
  ) {
    createCategoryList(input: $input, condition: $condition) {
      id
      title
      level
      parentID
      parentName
      hasChild
      createdAt
      updatedAt
    }
  }
`;
export const updateCategoryList = /* GraphQL */ `
  mutation UpdateCategoryList(
    $input: UpdateCategoryListInput!
    $condition: ModelCategoryListConditionInput
  ) {
    updateCategoryList(input: $input, condition: $condition) {
      id
      title
      level
      parentID
      parentName
      hasChild
      createdAt
      updatedAt
    }
  }
`;
export const deleteCategoryList = /* GraphQL */ `
  mutation DeleteCategoryList(
    $input: DeleteCategoryListInput!
    $condition: ModelCategoryListConditionInput
  ) {
    deleteCategoryList(input: $input, condition: $condition) {
      id
      title
      level
      parentID
      parentName
      hasChild
      createdAt
      updatedAt
    }
  }
`;
export const createMessageList = /* GraphQL */ `
  mutation CreateMessageList(
    $input: CreateMessageListInput!
    $condition: ModelMessageListConditionInput
  ) {
    createMessageList(input: $input, condition: $condition) {
      id
      title
      message
      backgroundColor
      letter
      author
      read
      date
      time
      userSub
      createdAt
      updatedAt
    }
  }
`;
export const updateMessageList = /* GraphQL */ `
  mutation UpdateMessageList(
    $input: UpdateMessageListInput!
    $condition: ModelMessageListConditionInput
  ) {
    updateMessageList(input: $input, condition: $condition) {
      id
      title
      message
      backgroundColor
      letter
      author
      read
      date
      time
      userSub
      createdAt
      updatedAt
    }
  }
`;
export const deleteMessageList = /* GraphQL */ `
  mutation DeleteMessageList(
    $input: DeleteMessageListInput!
    $condition: ModelMessageListConditionInput
  ) {
    deleteMessageList(input: $input, condition: $condition) {
      id
      title
      message
      backgroundColor
      letter
      author
      read
      date
      time
      userSub
      createdAt
      updatedAt
    }
  }
`;
export const createNotificationsList = /* GraphQL */ `
  mutation CreateNotificationsList(
    $input: CreateNotificationsListInput!
    $condition: ModelNotificationsListConditionInput
  ) {
    createNotificationsList(input: $input, condition: $condition) {
      id
      title
      message
      backgroundColor
      letter
      author
      read
      date
      time
      userSub
      createdAt
      updatedAt
    }
  }
`;
export const updateNotificationsList = /* GraphQL */ `
  mutation UpdateNotificationsList(
    $input: UpdateNotificationsListInput!
    $condition: ModelNotificationsListConditionInput
  ) {
    updateNotificationsList(input: $input, condition: $condition) {
      id
      title
      message
      backgroundColor
      letter
      author
      read
      date
      time
      userSub
      createdAt
      updatedAt
    }
  }
`;
export const deleteNotificationsList = /* GraphQL */ `
  mutation DeleteNotificationsList(
    $input: DeleteNotificationsListInput!
    $condition: ModelNotificationsListConditionInput
  ) {
    deleteNotificationsList(input: $input, condition: $condition) {
      id
      title
      message
      backgroundColor
      letter
      author
      read
      date
      time
      userSub
      createdAt
      updatedAt
    }
  }
`;
export const createBannerList = /* GraphQL */ `
  mutation CreateBannerList(
    $input: CreateBannerListInput!
    $condition: ModelBannerListConditionInput
  ) {
    createBannerList(input: $input, condition: $condition) {
      id
      title
      titletwo
      image
      description
      createdAt
      updatedAt
    }
  }
`;
export const updateBannerList = /* GraphQL */ `
  mutation UpdateBannerList(
    $input: UpdateBannerListInput!
    $condition: ModelBannerListConditionInput
  ) {
    updateBannerList(input: $input, condition: $condition) {
      id
      title
      titletwo
      image
      description
      createdAt
      updatedAt
    }
  }
`;
export const deleteBannerList = /* GraphQL */ `
  mutation DeleteBannerList(
    $input: DeleteBannerListInput!
    $condition: ModelBannerListConditionInput
  ) {
    deleteBannerList(input: $input, condition: $condition) {
      id
      title
      titletwo
      image
      description
      createdAt
      updatedAt
    }
  }
`;
export const createOrderReport = /* GraphQL */ `
  mutation CreateOrderReport(
    $input: CreateOrderReportInput!
    $condition: ModelOrderReportConditionInput
  ) {
    createOrderReport(input: $input, condition: $condition) {
      id
      title
      description
      orderID
      userID
      createdAt
      updatedAt
    }
  }
`;
export const updateOrderReport = /* GraphQL */ `
  mutation UpdateOrderReport(
    $input: UpdateOrderReportInput!
    $condition: ModelOrderReportConditionInput
  ) {
    updateOrderReport(input: $input, condition: $condition) {
      id
      title
      description
      orderID
      userID
      createdAt
      updatedAt
    }
  }
`;
export const deleteOrderReport = /* GraphQL */ `
  mutation DeleteOrderReport(
    $input: DeleteOrderReportInput!
    $condition: ModelOrderReportConditionInput
  ) {
    deleteOrderReport(input: $input, condition: $condition) {
      id
      title
      description
      orderID
      userID
      createdAt
      updatedAt
    }
  }
`;
export const createReturnProduct = /* GraphQL */ `
  mutation CreateReturnProduct(
    $input: CreateReturnProductInput!
    $condition: ModelReturnProductConditionInput
  ) {
    createReturnProduct(input: $input, condition: $condition) {
      id
      title
      description
      productID
      orderID
      userID
      createdAt
      updatedAt
    }
  }
`;
export const updateReturnProduct = /* GraphQL */ `
  mutation UpdateReturnProduct(
    $input: UpdateReturnProductInput!
    $condition: ModelReturnProductConditionInput
  ) {
    updateReturnProduct(input: $input, condition: $condition) {
      id
      title
      description
      productID
      orderID
      userID
      createdAt
      updatedAt
    }
  }
`;
export const deleteReturnProduct = /* GraphQL */ `
  mutation DeleteReturnProduct(
    $input: DeleteReturnProductInput!
    $condition: ModelReturnProductConditionInput
  ) {
    deleteReturnProduct(input: $input, condition: $condition) {
      id
      title
      description
      productID
      orderID
      userID
      createdAt
      updatedAt
    }
  }
`;
