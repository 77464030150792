import { View, Text, Pressable, TouchableOpacity, Image, Dimensions, FlatList, TextInput, StyleSheet } from 'react-native'
import React, {useState, useEffect} from 'react';
import { Ionicons, MaterialCommunityIcons, Octicons, AntDesign, Entypo, MaterialIcons  } from '@expo/vector-icons'; 
import { useNavigation, useRoute } from '@react-navigation/native';
import { API, graphqlOperation, Auth } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';

const {width, height} = Dimensions.get('window');

const ProductsScreen = () => {

    const route = useRoute().params
    const navigation = useNavigation();

    const mainColour = '#32a852';

  return (
    <View style={{flex: 1, backgroundColor: '#ffffff'}}>
      <View style={{marginTop: 15}}>
            <TouchableOpacity style={{marginLeft: 15}} onPress={()=> navigation.goBack()}>
                <Ionicons name="arrow-back-outline" size={24} color="black" />
            </TouchableOpacity>
        </View>
      <View style={{marginTop: 15, flexDirection: 'row'}}>
        <View style={{width: '33%', paddingLeft: 15, paddingTop: 30, borderRightWidth: 0.5, borderColor: '#d1d1d1', height: height, marginTop: 15, borderTopWidth: 0.5, borderColor: '#d1d1d1', alignItems: 'center'}}>
               {/* images */}
                <View>
                    <Image style={{height: 250, width: 250}} source={{uri: route?.item?.image}}/>
                </View>

                {/* second image flatlist */}

                {/* <View>
                    <FlatList
                    horizontal
                    data={route?.item?.images}
                    renderItem={renderImagesList}
                    />
                </View> */}
        </View>
        <View style={{width: '33%', borderRightWidth: 0.5, borderColor: '#d1d1d1', height: height, marginTop: 15, borderTopWidth: 0.5, borderColor: '#d1d1d1'}}>
                <View style={{alignSelf: 'center', marginBottom: 0, borderBottomWidth: 0.5, width: '100%', alignItems: 'center', borderColor: '#d1d1d1', height: 60, justifyContent: 'center'}}>
                    <Text style={{fontSize: 18, fontWeight: 'bold'}}>Details</Text>
                </View> 
                
                <View style={{margin: 15}}>
                <View style={{flexDirection: 'row'}}>
                    <Text style={{fontFamily: 13, width: '20%'}}>
                        Store
                    </Text>
                    <Text style={{marginLeft: 10, marginRight: 20}}>:</Text>
                    <Text style={{fontFamily: 13}}>
                        {route?.item?.shop || `--`}
                    </Text>
                </View> 

                <View style={{flexDirection: 'row'}}>
                    <Text style={{fontFamily: 13, width: '20%'}}>
                        Store ID
                    </Text>
                    <Text style={{marginLeft: 10, marginRight: 20}}>:</Text>
                    <Text style={{fontFamily: 13}}>
                        {route?.item?.shopID || `--`}
                    </Text>
                </View> 

                <View style={{flexDirection: 'row'}}>
                    <Text style={{fontFamily: 13, width: '20%'}}>
                        Title
                    </Text>
                    <Text style={{marginLeft: 10, marginRight: 20}}>:</Text>
                    <Text style={{fontFamily: 13}}>
                        {route?.item?.title}
                    </Text>
                </View> 
                <View style={{flexDirection: 'row'}}>
                    <Text style={{fontFamily: 13, width: '20%'}}>
                        Brand
                    </Text>
                    <Text style={{marginLeft: 10, marginRight: 20}}>:</Text>
                    <Text style={{fontFamily: 13}}>
                        {route?.item?.brand || `--`}
                    </Text>
                </View> 
                <View style={{flexDirection: 'row'}}>
                    <Text style={{fontFamily: 13, width: '20%'}}>
                        Category
                    </Text>
                    <Text style={{marginLeft: 10, marginRight: 20}}>:</Text>
                    <Text style={{fontFamily: 13}}>
                        {route?.item?.category}
                    </Text>
                </View> 
                <View style={{flexDirection: 'row'}}>
                    <Text style={{fontFamily: 13, width: '20%'}}>
                        Isle
                    </Text>
                    <Text style={{marginLeft: 10, marginRight: 20}}>:</Text>
                    <Text style={{fontFamily: 13}}>
                        {route?.item?.isle}
                    </Text>
                </View>
                <View style={{flexDirection: 'row'}}>
                    <Text style={{fontFamily: 13, width: '20%'}}>
                        Group
                    </Text>
                    <Text style={{marginLeft: 10, marginRight: 20}}>:</Text>
                    <Text style={{fontFamily: 13}}>
                        {route?.item?.group}
                    </Text>
                </View>  
                <View style={{flexDirection: 'row'}}>
                    <Text style={{fontFamily: 13, width: '20%'}}>
                        Price
                    </Text>
                    <Text style={{marginLeft: 10, marginRight: 20}}>:</Text>
                    <Text style={{fontFamily: 13}}>
                        R{Number(route?.item?.price).toFixed(2)}
                    </Text>
                </View> 
                <View style={{flexDirection: 'row'}}>
                    <Text style={{fontFamily: 13, width: '20%'}}>
                        Quantity
                    </Text>
                    <Text style={{marginLeft: 10, marginRight: 20}}>:</Text>
                    <Text style={{fontFamily: 13}}>
                        {route?.item?.quantity}
                    </Text>
                </View> 
                <View style={{flexDirection: 'row'}}>
                    <Text style={{fontFamily: 13, width: '20%'}}>
                        Sizes
                    </Text>
                    <Text style={{marginLeft: 10, marginRight: 20}}>:</Text>
                    <Text style={{fontFamily: 13}}>
                        {route?.item?.sizes}
                    </Text>
                </View> 
                <View style={{flexDirection: 'row'}}>
                    <Text style={{fontFamily: 13, width: '20%'}}>
                        Gender
                    </Text>
                    <Text style={{marginLeft: 10, marginRight: 20}}>:</Text>
                    <Text style={{fontFamily: 13}}>
                        {route?.item?.gender || `Neutral`}
                    </Text>
                </View>

                <View style={{flexDirection: 'row'}}>
                    <Text style={{fontFamily: 13, width: '20%'}}>
                        Description
                    </Text>
                    <Text style={{marginLeft: 10, marginRight: 20}}>:</Text>
                    <Text style={{fontFamily: 13}}>
                        {route?.item?.description || `--`}
                    </Text>
                </View>  




                </View>



        </View>
        <View style={{width: '34%', borderRightWidth: 0, borderColor: '#d1d1d1', height: height, marginTop: 15, borderTopWidth: 0.5, borderColor: '#d1d1d1'}}>
                <View style={{alignSelf: 'center', marginBottom: 0, borderBottomWidth: 0.5, width: '100%', alignItems: 'center', borderColor: '#d1d1d1', height: 60, justifyContent: 'center'}}>
                    <Text style={{fontSize: 18, fontWeight: 'bold'}}>Options</Text>
                </View>  

                <TouchableOpacity onPress={()=> {}} style={{ borderBottomWidth: 1, borderColor: '#d1d1d1', width: '100%', height: 60, flexDirection: 'row', borderTopWidth: 0, alignItems: 'center'}}>
                
                <View style={{marginLeft: 15, marginRight: 15, width: 40, height: 30, backgroundColor: mainColour, borderRadius: 5, alignItems: 'center', justifyContent: 'center'}}>
                    <Entypo  name="check" size={20} color="white" />
                </View>
                
                <Text>Set Discount</Text>
                </TouchableOpacity>  

                <TouchableOpacity onPress={()=> {}} style={{ borderBottomWidth: 1, borderColor: '#d1d1d1', width: '100%', height: 60, flexDirection: 'row', borderTopWidth: 0, alignItems: 'center'}}>
                
                <View style={{marginLeft: 15, marginRight: 15, width: 40, height: 30, backgroundColor: mainColour, borderRadius: 5, alignItems: 'center', justifyContent: 'center'}}>
                    <Entypo name="check" size={20} color="white" />
                </View>
                
                <Text>Pause Product</Text>
                </TouchableOpacity>

                <TouchableOpacity onPress={()=> {}} style={{ borderBottomWidth: 1, borderColor: '#d1d1d1', width: '100%', height: 60, flexDirection: 'row', borderTopWidth: 0, alignItems: 'center'}}>
                
                <View style={{marginLeft: 15, marginRight: 15, width: 40, height: 30, backgroundColor: mainColour, borderRadius: 5, alignItems: 'center', justifyContent: 'center'}}>
                    <Entypo name="check" size={20} color="white" />
                </View>
                
                <Text>Set Unavailable</Text>
                </TouchableOpacity>

                <TouchableOpacity onPress={()=> {}} style={{ borderBottomWidth: 1, borderColor: '#d1d1d1', width: '100%', height: 60, flexDirection: 'row', borderTopWidth: 0, alignItems: 'center'}}>
                
                <View style={{marginLeft: 15, marginRight: 15, width: 40, height: 30, backgroundColor: mainColour, borderRadius: 5, alignItems: 'center', justifyContent: 'center'}}>
                    <Entypo name="check" size={20} color="white" />
                </View>
                
                <Text>Re-Order</Text>
                </TouchableOpacity>

                <TouchableOpacity onPress={()=> {}} style={{ borderBottomWidth: 1, borderColor: '#d1d1d1', width: '100%', height: 60, flexDirection: 'row', borderTopWidth: 0, alignItems: 'center'}}>
                
                <View style={{marginLeft: 15, marginRight: 15, width: 40, height: 30, backgroundColor: mainColour, borderRadius: 5, alignItems: 'center', justifyContent: 'center'}}>
                    <Entypo name="check" size={20} color="white" />
                </View>
                
                <Text>Update Product</Text>
                </TouchableOpacity>

        </View>
      </View>
    </View>
  )
}

export default ProductsScreen

const styles = StyleSheet.create({})